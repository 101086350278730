import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), 
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home')
    },
    {
      path: '/privacyView',
      name: 'privacyView',
      component: () => import('@/views/privacyView')
    },
    {
      path: '/category/:name?',
      name: 'category',
      component: () => import('@/views/Category')
    },
    {
      path: '/rank',
      name: 'rank',
      component: () => import('@/views/Rank')
    },
    {
      path: '/book/:id',
      name: 'book',
      component: () => import('@/views/Detail')
	   
    },
    {
      path: '/chapter_list/:bookId',
      name: 'chapterList',
      component: () => import('@/views/ChapterList')
	   
    },
    {
      path: '/book/:id/:chapterId',
      name: 'read',
      component: () => import('@/views/Read')
	   
    }
  ]
})

// 解决 vue 中路由跳转时，总是从新页面中间开始显示
router.afterEach((to,from,next) => {
  window.scrollTo(0,0)
    // var scripts = document.getElementsByTagName('script');
    // for (var i = 0; i < scripts.length; i++) {
    //   var src = scripts[i].getAttribute('src');
    //   if (src) {
    //     console.log('当前页面加载的JavaScript文件:', src);
    //   }
    // }
})
// router.beforeEach((to, from, next) => {  
//  const arr =  JSON.parse(sessionStorage.getItem('routeList')) ?? []
//  sessionStorage.removeItem('routeList')
//   const list = []
//   arr.push(from)
//   sessionStorage.setItem('routeList',JSON.stringify(arr))
//   next();  
// }); 

export default router